import React from 'react';
import { Link } from 'react-router-dom';
import styles from './../Style/Intro.module.css';
import Footer from './Footer';

function SanshishuOverview() {
    return (
        <div className={styles.articleContainer}>
            <div className={styles.articleContentWrapper}>
                <header className={styles.articleHeader}>
                    <h1>《三世書》概述</h1>
                    <p>揭示前世、今生與來世的因果關係的命理預言書</p>
                </header>

                <section className={styles.articleContent}>
                    <p>
                        《三世書》是一部源自中國民間的預言書，據說可以通過推算個人前世、今生與來世的命運，提供關於個人命運的指引。此書被廣泛應用於算命、批命，主要以“因果報應”和“善惡有報”為核心思想。
                    </p>

                    <h3>一、前世與因果</h3>
                    <p>
                        《三世書》主張，人的前世行為決定了今生的命運。某些生活中的苦難、貧困或是富貴與幸福，都是前世行為的回報。書中經常引用“種瓜得瓜，種豆得豆”這句話，來解釋因果報應的道理。例如，前世行善積德的人，今生可能享有富貴榮華；而那些作惡多端者，則會承受苦難與貧窮。
                    </p>

                    <h3>二、今生的行為</h3>
                    <p>
                        在《三世書》中，今生的行為對來世的命運具有關鍵影響。今生的選擇和行為，是改變未來命運的重要因素。它鼓勵人們修身養性、行善積德，特別是對於善行的鼓勵。例如，幫助他人、孝敬父母、忠誠於朋友，都是會帶來福報的行為。
                    </p>

                    <h3>三、來世的福報</h3>
                    <p>
                        《三世書》中的來世，反映了對人生循環的信仰。來世的命運取決於今生的作為：今生行善者，來世會更加順遂幸福；而作惡者，來世可能遭遇更大的災難與苦難。這種對來世的描述意圖勸導人們在今生避免不義行為，並積極行善，從而造福未來。
                    </p>

                    <h3>四、如何改變命運</h3>
                    <p>
                        雖然命運受前世的影響，但《三世書》也強調今生行為的力量。通過積極改變，命運是可以扭轉的。書中經常提到“積善之家，必有餘慶；積不善之家，必有餘殃”，鼓勵人們通過行善、學習道德、改過自新來影響自己的命運走向。
                    </p>

                    <h3>五、對今人的啟示</h3>
                    <p>
                        對於現代人來說，《三世書》中的因果理念仍具有現實意義。它強調道德的重要性，並提醒我們每個人的行為都會產生深遠的影響。無論是否相信前世來生，《三世書》提醒我們珍惜今生，並通過自己的努力去塑造一個更美好的未來。
                    </p>
                </section>
                <div className={styles.linkContainer}>
                    <Link to="/ming/ThreeLifetimesResult" className={styles.searchLink}>
                        查詢三世書
                    </Link>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default SanshishuOverview;

import React from 'react';
import styles from './../Style/Intro.module.css';
import Footer from './Footer';

function GreenBeanBenefits() {
    return (
        <div className={styles.articleContainer}>
            <div className={styles.articleContentWrapper}>
                <header className={styles.articleHeader}>
                    <h1 className={styles.title}>綠豆的營養成分與功效</h1>
                    <p className={styles.subtitle}>綠豆是一種富含多種營養的豆類，對健康有多方面的益處。</p>
                </header>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>綠豆的營養成分</h2>
                    <p>每100克乾綠豆大致營養成分如下：</p>
                    <ul className={styles.list}>
                        <li>熱量：347 kcal</li>
                        <li>蛋白質：23.9 克</li>
                        <li>脂肪：1.15 克</li>
                        <li>碳水化合物：62.6 克</li>
                        <li>膳食纖維：16.3 克</li>
                        <li>鈣：132 毫克</li>
                        <li>鐵：6.7 毫克</li>
                        <li>鉀：1246 毫克</li>
                        <li>磷：367 毫克</li>
                        <li>葉酸：625 微克</li>
                        <li>維生素B群：包括B1、B2、B3、B6</li>
                        <li>抗氧化劑：多酚、黃酮類化合物</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>綠豆的好處</h2>

                    <h3 className={styles.sectionTitle}>1. 清熱解毒與消炎</h3>
                    <p>綠豆具有顯著的清熱解毒作用，特別適合在炎熱天氣中食用。中醫認為綠豆可以消除體內熱毒，對皮膚問題如痱子、皮疹等有幫助。</p>

                    <h3 className={styles.sectionTitle}>2. 促進消化與腸道健康</h3>
                    <p>綠豆中的膳食纖維（每100克含約16.3克）能促進腸道蠕動，預防便秘，並且有助於維持腸道菌群的平衡，進而幫助控制體重。</p>

                    <h3 className={styles.sectionTitle}>3. 降低膽固醇與保護心血管</h3>
                    <p>綠豆中的纖維和植物甾醇有助於降低血清膽固醇，從而減少動脈硬化的風險。此外，多酚類抗氧化劑能中和自由基，保護心血管系統免受氧化壓力的損害。</p>

                    <h3 className={styles.sectionTitle}>4. 支持免疫系統與補血</h3>
                    <p>綠豆含有豐富的鐵，有助於改善貧血症狀，並提升免疫力。其維生素B群和鋅也能增強免疫功能，促進身體抵抗感染。</p>

                    <h3 className={styles.sectionTitle}>5. 抗氧化與抗衰老</h3>
                    <p>綠豆中的多酚和黃酮類抗氧化劑能中和自由基，減少氧化損傷，延緩衰老，並保護皮膚健康。</p>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>綠豆的壞處</h2>

                    <h3 className={styles.sectionTitle}>1. 過量攝入可能導致消化不適</h3>
                    <p>綠豆含有寡糖，這些成分不易被完全分解，可能導致腹脹、脹氣等不適症狀，特別是腸胃功能較弱的人群。</p>

                    <h3 className={styles.sectionTitle}>2. 對寒性體質不利</h3>
                    <p>綠豆屬寒性食物，體質虛寒者若過量食用，可能導致手腳冰冷、腹瀉等寒性症狀。</p>

                    <h3 className={styles.sectionTitle}>3. 腎功能不全者應慎食</h3>
                    <p>綠豆中含有較高的鉀和磷，對於腎功能不全患者來說，過量攝入可能會導致電解質失衡，應在醫生指導下食用。</p>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>適合食用綠豆的人群</h2>
                    <ul className={styles.list}>
                        <li>容易上火或處於炎熱環境的人群</li>
                        <li>便秘或腸道健康需要改善者</li>
                        <li>心血管疾病患者</li>
                        <li>貧血或免疫力低下者</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>不適合食用綠豆的人群</h2>
                    <ul className={styles.list}>
                        <li>體質虛寒者</li>
                        <li>腎臟病患者</li>
                        <li>腸胃功能較弱者</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>綠豆的常見烹飪方式</h2>
                    <ul className={styles.list}>
                        <li>綠豆湯：常見清熱解暑甜品，適合夏季食用。</li>
                        <li>綠豆糕：傳統中式點心，口感細膩香甜。</li>
                        <li>綠豆芽：營養豐富，常用於涼拌或炒食。</li>
                        <li>綠豆粥：中式早餐中的常見選擇，具有清熱解暑的功效。</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>五行的影響</h2>
                    <ul className={styles.list}>
                        <li>五行屬性：綠豆屬於「木」，在五行中與春季和肝臟相關，有助於提升肝臟的功能，促進血液循環和新陳代謝。</li>
                    </ul>
                </section>

                <Footer />
            </div>
        </div>
    );
}

export default GreenBeanBenefits;

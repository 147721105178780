import React from 'react';
import styles from './../Style/Intro.module.css';
import Footer from './Footer';

function RedBeanBenefits() {
    return (
        <div className={styles.articleContainer}>
            <div className={styles.articleContentWrapper}>
                <header className={styles.articleHeader}>
                    <h1 className={styles.title}>紅豆的營養成分與功效</h1>
                    <p className={styles.subtitle}>紅豆是一種富含多種營養的豆類，對健康有多方面的益處。</p>
                </header>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>紅豆的營養成分</h2>
                    <p>每100克乾紅豆大致營養成分如下：</p>
                    <ul className={styles.list}>
                        <li>熱量：329 kcal</li>
                        <li>蛋白質：19.9 克</li>
                        <li>脂肪：0.53 克</li>
                        <li>碳水化合物：62.9 克</li>
                        <li>膳食纖維：12.7 克</li>
                        <li>鈣：66 毫克</li>
                        <li>鐵：5.1 毫克</li>
                        <li>鉀：1254 毫克</li>
                        <li>鋅：3.3 毫克</li>
                        <li>葉酸：622 微克</li>
                        <li>維生素B群：包括B1、B2、B3、B6</li>
                        <li>抗氧化劑：多酚、黃酮類化合物</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>紅豆的好處</h2>

                    <h3 className={styles.subsectionTitle}>1. 促進消化健康</h3>
                    <p>紅豆中的膳食纖維（每100克含約12.7克）能促進腸道蠕動，預防便秘，並且有助於維持腸道菌群的平衡，進而幫助控制體重。</p>

                    <h3 className={styles.subsectionTitle}>2. 有助於控制體重與穩定血糖</h3>
                    <p>紅豆屬於低升糖指數食物，消化速度較慢，不會引起血糖迅速上升，特別適合糖尿病患者或需要穩定血糖的人群。</p>

                    <h3 className={styles.subsectionTitle}>3. 改善心血管健康</h3>
                    <p>紅豆中的鉀有助於調節血壓，減少鈉的作用，並且膳食纖維有助於降低膽固醇，進而減少心血管疾病風險。</p>

                    <h3 className={styles.subsectionTitle}>4. 增強免疫系統</h3>
                    <p>紅豆富含鐵、鋅，這些礦物質促進紅血球與白血球的生成，幫助身體抵抗感染，並改善貧血狀況。</p>

                    <h3 className={styles.subsectionTitle}>5. 抗氧化與抗衰老</h3>
                    <p>紅豆中的多酚和黃酮類抗氧化劑能中和自由基，減少氧化應激，進而保護皮膚健康，減緩衰老。</p>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>紅豆的壞處</h2>

                    <h3 className={styles.subsectionTitle}>1. 腎臟負擔</h3>
                    <p>紅豆中的鉀含量高（1254毫克/100克），對於腎功能不全的患者來說，過量攝入可能導致高鉀血症，加重腎臟負擔。</p>

                    <h3 className={styles.subsectionTitle}>2. 胃腸不適</h3>
                    <p>紅豆含有不易消化的寡糖，可能會引起腹脹或腸胃不適，特別是對於腸易激綜合症（IBS）患者。</p>

                    <h3 className={styles.subsectionTitle}>3. 磷含量較高</h3>
                    <p>紅豆含有較高的磷，對於慢性腎病患者可能導致鈣磷失衡，影響骨骼與心臟健康。</p>

                    <h3 className={styles.subsectionTitle}>4. 高嘌呤含量</h3>
                    <p>紅豆嘌呤含量較高，對於痛風患者或尿酸偏高的人群應適量食用，以免引發痛風。</p>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>適合食用紅豆的人群</h2>
                    <ul className={styles.list}>
                        <li>糖尿病患者：低GI有助於穩定血糖。</li>
                        <li>貧血或免疫力低下者：富含鐵，有助於紅血球生成並增強免疫。</li>
                        <li>心血管疾病患者：鉀和膳食纖維能幫助控制血壓和膽固醇。</li>
                        <li>素食者或素食主義者：植物性蛋白質和鐵的優質來源。</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>不適合食用紅豆的人群</h2>
                    <ul className={styles.list}>
                        <li>腎臟病患者：鉀和磷含量過高。</li>
                        <li>痛風或高尿酸患者：嘌呤含量高。</li>
                        <li>胃腸易敏感者：不易消化的寡糖可能引起腸胃不適。</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>紅豆的常見烹飪方式</h2>
                    <ul className={styles.list}>
                        <li>紅豆湯：常見甜品，紅豆煮軟後加糖製成。</li>
                        <li>紅豆餅或紅豆沙包：紅豆餡常作為甜餡料使用，如紅豆包、紅豆年糕等。</li>
                        <li>紅豆飯：與糯米搭配煮成紅豆飯，常用於節慶或特別場合。</li>
                        <li>紅豆沙冰：夏季甜品，紅豆與剉冰搭配食用。</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>五行的影響</h2>
                    <ul className={styles.list}>
                        <li>五行屬性：紅豆屬於「土」，在五行中與脾臟相關，能夠補益脾胃，增強消化能力，維持身體的穩定性。</li>
                        <li>調節情緒：因為土能夠生金，紅豆的食用對於情緒的穩定及心情的調適有著良好的影響，特別是對於有焦慮或情緒波動的人。</li>
                    </ul>
                </section>

                <Footer />
            </div>
        </div>
    );
}

export default RedBeanBenefits;

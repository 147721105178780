import React from 'react';
import styles from './../Style/Intro.module.css';
import Footer from './Footer';

function SolarTerm() {
    return (
        <div className={styles.articleContainer}>
            <div className={styles.articleContentWrapper}>
                <header className={styles.articleHeader}>
                    <h1 className={styles.title}>水逆是什麼？</h1>
                    <p className={styles.subtitle}>了解水星逆行的現象及其影響</p>
                </header>

                <section className={styles.articleContent}>
                    <p>
                        "水逆" 是 "水星逆行" 的簡稱，是占星學中的一個術語。它指的是從地球上觀察，水星看似在其軌道上逆行的現象。這並不意味著水星真的倒退，而是由於地球和水星之間的相對運動速度差異，從地球的視角看，水星會在某些時候看起來像是倒退了一樣。
                    </p>

                    <h2 className={styles.sectionTitle}>水星逆行的意義</h2>
                    <p>
                        在占星學中，水星代表著溝通、旅行、技術、信息交換和商業事務等。因此，當水星逆行時，這些領域可能會受到影響，人們會感受到困擾或障礙，比如：
                    </p>
                    <ul className={styles.list}>
                        <li>溝通不暢、誤解增加</li>
                        <li>計劃被打亂、旅行延誤</li>
                        <li>技術問題增多（如設備故障、數據丟失）</li>
                        <li>再考慮過去的事情、遇到舊人舊事</li>
                    </ul>

                    <h2 className={styles.sectionTitle}>如何判斷水逆來了</h2>
                    <p>
                        水星逆行的時期通常一年會發生三到四次，每次大約持續三個星期。這些逆行期的日期是可以提前計算的。要判斷水星是否處於逆行期，可以參考天文曆或專門的占星網站。
                    </p>

                    <h3 className={styles.sectionTitle}>例子：2024年水星逆行的日期（可能的日期）</h3>
                    <ul className={styles.list}>
                        <li>2024年1月1日 - 1月25日</li>
                        <li>2024年5月17日 - 6月11日</li>
                        <li>2024年9月9日 - 9月29日</li>
                        <li>2024年12月19日 - 2025年1月7日</li>
                    </ul>
                </section>

                <Footer />
            </div>
        </div>
    );
}

export default SolarTerm;

// Footer.js
import React from 'react';
import styles from './../Style/Footer.module.css';

function Footer() {
    const goBack = () => {
        window.history.back();
    };
    return (
        <footer className={styles.footer}>
            <p>&copy; {new Date().getFullYear()} 文章作者。保留所有權利。</p>
            <button className={styles.backButton} onClick={goBack}>回上一頁</button>
        </footer>
    );
}

export default Footer;

// ArticleLoader.js
import React, { useState } from 'react';
import SolarTerm from './Articles/SolarTerm';
import MercuryRetrograde from './Articles/MercuryRetrograde';
import WuXingOrigin from './Articles/WuXingOrigin';
import TianganOrigin from './Articles/TianganOrigin';
import DizhiOrigin from './Articles/DizhiOrigin';
import MungBeanBenefits from './Articles/MungBeanBenefits';
import RedBeanBenefits from './Articles/RedBeanBenefits';
import BlackMushroomBenefits from './Articles/BlackMushroomBenefits';

import styles from './Style/ArticleLoader.module.css';

// 定義文章數據
const articles = {
    '20240829': {
        component: SolarTerm,
        title: '二十四節氣介紹',
        description: '二十四節氣是基於太陽在黃道上的位置劃分的，每個節氣對應著太陽在黃道上的特定位置。因此，每個節氣的時間通常非常固定，但因為地球繞太陽運行的週期不是精確的365天，因此每年會有細微的變化。',
        url: '/SolarTerm'
    },
    '20240830': {
        component: MercuryRetrograde,
        title: '水逆是什麼？',
        description: '"水逆" 是 "水星逆行" 的簡稱，是占星學中的一個術語。它指的是從地球上觀察，水星看似在其軌道上逆行的現象。這並不意味著水星真的倒退，而是由於地球和水星之間的相對運動速度差異，從地球的視角看，水星會在某些時候看起來像是倒退了一樣。',
        url: '/MercuryRetrograde'
    },
    '20240901': {
        component: WuXingOrigin,
        title: '中國五行的由來',
        description: '中國的五行學說起源於古代中國的哲學思想，是中國傳統文化中一個重要的宇宙觀和自然哲學體系。五行學說認為宇宙萬物是由五種基本元素構成的：木、火、土、金、水。五行之間存在相生相克的關係，這種關係用於解釋自然現象、人類社會、人體健康等多個方面。',
        url: '/WuXingOrigin'
    },
    '20240902_Tiangan': {
        component: TianganOrigin,
        title: '天干的由來',
        description: '天干（干支）系統源於古代中國的天文觀察和對自然界變化的理解。天干與地支結合，用於表示時間、日期、方向和占卜。這套系統的形成反映了古人對宇宙運行規律的探索。',
        url: '/TianganOrigin'
    },
    '20240902_Dizhi': {
        component: DizhiOrigin,
        title: '地支的由來',
        description: '地支系統是中國古代的一種時間記錄和占卜系統，與天干系統共同使用，形成了干支紀年法。地支與天文觀察和自然界變化有關，這套系統反映了古人對宇宙運行規律的理解。',
        url: '/DizhiOrigin'
    },
    '20240920_Dizhi': {
        component: DizhiOrigin,
        title: '三世書',
        description: '《三世書》是一部源自中國民間的預言書，據說可以通過推算個人前世、今生與來世的命運，提供關於個人命運的指引。此書被廣泛應用於算命、批命，主要以“因果報應”和“善惡有報”為核心思想。',
        url: '/Sanshishu'
    },
    '20241001': {
        component: MungBeanBenefits,
        title: '綠豆的營養成分與功效',
        description: '綠豆是一種富含多種營養的豆類，對健康有多方面的益處',
        url: '/MungBeanBenefits'
    },
    '20241002': {
        component: RedBeanBenefits,
        title: '紅豆的營養成分與功效',
        description: '紅豆是一種富含多種營養的豆類，對健康有多方面的益處',
        url: '/RedBeanBenefits'
    },
    '20241003': {
        component: BlackMushroomBenefits,
        title: '黑木耳的營養成分與功效',
        description: '黑木耳是一種常見於東亞地區的食用真菌，含有豐富的營養成分',
        url: '/BlackMushroomBenefits'
    },
};

// 分頁獲取文章
export const getPaginatedArticles = (page = 1, pageSize = 6) => {
    const sortedDates = Object.keys(articles).sort().reverse();
    const start = (page - 1) * pageSize;
    const end = page * pageSize;
    const paginatedDates = sortedDates.slice(start, end);
    return paginatedDates.map(date => articles[date]);
};

// 總頁數計算
export const getTotalPages = (pageSize = 6) => {
    const totalArticles = Object.keys(articles).length;
    return Math.ceil(totalArticles / pageSize);
};

// React 組件來顯示文章和分頁
const ArticleLoader = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 6; // 每頁顯示 6 篇文章
    const totalPages = getTotalPages(pageSize);
    const paginatedArticles = getPaginatedArticles(currentPage, pageSize);

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    return (
        <div>
            <h1 className={styles.title}>文章列表</h1>
            <ul className={styles.articleList}>
                {paginatedArticles.map((article, index) => (
                    <li key={index} className={styles.articleItem}>
                        <h2 className={styles.articleTitle}>{article.title}</h2>
                        <p className={styles.articleDescription}>{article.description}</p>
                        <a href={article.url} className={styles.readMore}>閱讀更多</a>
                        {index < paginatedArticles.length - 1 && <hr className={styles.articleSeparator} />} {/* 添加分隔线 */}
                    </li>
                ))}
            </ul>

            <div className={styles.pagination}>
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    上一頁
                </button>
                <span className={styles.pageInfo}> 頁數 {currentPage} / {totalPages} </span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    下一頁
                </button>
            </div>
        </div>
    );
};

export default ArticleLoader;

import React, { useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from './Style/Ming.module.css'; // 引入新的CSS文件

function Ming() {
    const [birthdate, setBirthdate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const calendarRef = useRef(null);
    const { type } = useParams(); // 获取算命类型参数
    const today = new Date();
    const ninetyYearsAgo = new Date();
    ninetyYearsAgo.setFullYear(today.getFullYear() - 90);

    const [birthtime, setBirthtime] = useState('');
    const navigate = useNavigate();

    const handleInputClick = () => {
        setShowCalendar(!showCalendar); // 切换日历的显示状态
    };

    const handleDateChange = (date) => {
        console.log('Date changed:', date);
        setBirthdate(date);
        setShowCalendar(false); // 关闭日历
    };

    const handleTimeChange = (event) => {
        const { name, value } = event.target;
        if (name === 'birthtime') {
            setBirthtime(value);
        }
    };

    const formatDate = (date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate(`/${type}`, { state: { birthdate: formatDate(birthdate), birthtime } });
    };

    const timeOptions = [
        { label: '子 (23:00-01:00)', value: '23:00' },
        { label: '丑 (01:00-03:00)', value: '01:00' },
        { label: '寅 (03:00-05:00)', value: '03:00' },
        { label: '卯 (05:00-07:00)', value: '05:00' },
        { label: '辰 (07:00-09:00)', value: '07:00' },
        { label: '巳 (09:00-11:00)', value: '09:00' },
        { label: '午 (11:00-13:00)', value: '11:00' },
        { label: '未 (13:00-15:00)', value: '13:00' },
        { label: '申 (15:00-17:00)', value: '15:00' },
        { label: '酉 (17:00-19:00)', value: '17:00' },
        { label: '戌 (19:00-21:00)', value: '19:00' },
        { label: '亥 (21:00-23:00)', value: '21:00' },
    ];

    return (
        <div className={styles.container}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <label className={styles.label}>
                    出生日期:
                    <div className={styles.dateContainer}>
                        <input
                            type="text"
                            value={formatDate(birthdate)}
                            readOnly
                            className={styles.dateInput}
                        />
                        <div
                            className={styles.calendarIcon}
                            onClick={handleInputClick}
                        />
                    </div>
                    {showCalendar && (
                        <div ref={calendarRef} className={styles.calendar}>
                            <Calendar
                                onChange={handleDateChange}
                                value={birthdate}
                                minDate={ninetyYearsAgo}
                                maxDate={today}
                            />
                        </div>
                    )}
                </label>
                <label className={styles.label}>
                    出生時間:
                    <select
                        name="birthtime"
                        value={birthtime}
                        onChange={handleTimeChange}
                        required
                        className={styles.select}
                    >
                        <option value="">請選擇時間</option>
                        {timeOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                </label>
                <button type="submit" className={styles.submitButton}>提交</button>
            </form>
        </div>
    );
}

export default Ming;

import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './../Style/Intro.module.css';
import Footer from './Footer';

function BlackFungusBenefits() {
    return (
        <div className={styles.articleContainer}>
            <Helmet>
                <title>黑木耳的營養成分與功效</title>
                <meta name="description" content="了解黑木耳的營養成分及其對健康的多種好處，包括降低血脂、促進腸道健康等。" />
                <meta name="keywords" content="黑木耳, 營養成分, 功效, 健康, 養生" />
            </Helmet>
            <div className={styles.articleContentWrapper}>
                <header className={styles.articleHeader}>
                    <h1 className={styles.title}>黑木耳的營養成分與功效</h1>
                    <p className={styles.subtitle}>黑木耳是一種常見於東亞地區的食用真菌，含有豐富的營養成分。</p>
                </header>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>黑木耳的營養成分</h2>
                    <p>根據數據顯示，每100克乾燥黑木耳的營養成分如下：</p>
                    <ul className={styles.list}>
                        <li>熱量：284 kcal</li>
                        <li>蛋白質：10.6 克</li>
                        <li>脂肪：0.73 克</li>
                        <li>碳水化合物：73 克</li>
                        <li>膳食纖維：30.7 克</li>
                        <li>鈣：157 毫克</li>
                        <li>鐵：13.9 毫克</li>
                        <li>鎂：194 毫克</li>
                        <li>鉀：754 毫克</li>
                        <li>磷：242 毫克</li>
                        <li>維生素B群：包括B2和B3等，對於代謝功能十分有益</li>
                        <li>多糖：含有多種活性多糖，具有抗氧化和免疫調節作用</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>黑木耳的好處</h2>

                    <h3 className={styles.subsectionTitle}>1. 降血脂與保護心血管健康</h3>
                    <p>黑木耳含有大量膳食纖維和植物多糖，能幫助減少血脂水平，特別是降低低密度脂蛋白（LDL，壞膽固醇）。這對於預防動脈粥樣硬化和保持血管彈性非常有幫助。同時，黑木耳中的多糖有助於促進血液循環，預防血栓形成，從而保護心血管系統。</p>

                    <h3 className={styles.subsectionTitle}>2. 促進腸道健康與排毒</h3>
                    <p>黑木耳富含膳食纖維，尤其是不溶性纖維，能夠增加腸道蠕動，幫助排出體內廢物和毒素，防止便秘並維持腸道健康。它也有助於減少腸內對脂肪和膽固醇的吸收，對於控制體重和保護消化系統都有益處。</p>

                    <h3 className={styles.subsectionTitle}>3. 抗氧化與抗衰老作用</h3>
                    <p>黑木耳中含有大量的植物多糖和抗氧化物質，能有效中和自由基，減少體內的氧化壓力，這有助於延緩細胞老化，保護皮膚健康，並降低罹患慢性疾病的風險。</p>

                    <h3 className={styles.subsectionTitle}>4. 增強免疫力</h3>
                    <p>黑木耳含有活性多糖，這些多糖具有免疫調節作用，能夠促進免疫細胞的活性，增強機體對病毒和細菌的抵抗力。對於體質較弱或經常感冒的人群，黑木耳有助於增強免疫系統的功能。</p>

                    <h3 className={styles.subsectionTitle}>5. 抗凝血與促進血液循環</h3>
                    <p>黑木耳中富含植物膠質，能夠促進血液流動，具有一定的抗凝血效果。它被認為可以防止血液黏稠，促進血液循環，這對於預防血栓、動脈硬化、冠心病等心血管疾病都有幫助。</p>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>黑木耳的壞處</h2>

                    <h3 className={styles.subsectionTitle}>1. 過量食用可能影響凝血功能</h3>
                    <p>由於黑木耳具有抗凝血效果，過量攝入可能導致凝血功能降低，對於正在服用抗凝血藥物或患有出血性疾病的患者來說，應謹慎食用，以免增加出血風險。</p>

                    <h3 className={styles.subsectionTitle}>2. 胃腸不適與消化不良</h3>
                    <p>黑木耳中高含量的纖維對於某些人來說，尤其是胃腸功能較弱的人，可能引發消化不良、腹脹或腹瀉等問題。因此，應適量食用，以免給消化系統帶來負擔。</p>

                    <h3 className={styles.subsectionTitle}>3. 變質黑木耳有毒性風險</h3>
                    <p>乾燥黑木耳在泡發後若長時間放置不當，容易滋生細菌，特別是變質黑木耳可能會產生有毒物質，如椰毒假單胞菌產生的米酵菌酸，攝入後可能引發中毒反應，嚴重時甚至可能危及生命。因此，黑木耳應該新鮮食用，並避免長時間放置。</p>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>適合食用黑木耳的人群</h2>
                    <ul className={styles.list}>
                        <li>心血管疾病患者：黑木耳具有降血脂和抗凝血作用。</li>
                        <li>便秘或需要促進腸道健康者：富含膳食纖維，能幫助預防和改善便秘。</li>
                        <li>免疫力較弱的人群：增強免疫系統功能。</li>
                        <li>高血脂人群：能幫助降低壞膽固醇。</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>不適合食用黑木耳的人群</h2>
                    <ul className={styles.list}>
                        <li>正在服用抗凝血藥物的人群：可能會增加出血風險。</li>
                        <li>出血性疾病患者：可能會加重病情。</li>
                        <li>胃腸功能較弱者：可能引發腹脹或消化不良。</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>黑木耳的常見烹飪方式</h2>
                    <ul className={styles.list}>
                        <li>涼拌黑木耳：將泡發好的黑木耳焯水後，加入調料拌勻即可。</li>
                        <li>黑木耳炒菜：常與蔬菜或肉類一同炒製，簡單易做。</li>
                        <li>黑木耳煲湯：與其他食材煲湯，增強滋補效果。</li>
                        <li>黑木耳燉品：與紅棗、枸杞等燉煮，補血養氣。</li>
                        <li>黑木耳餃子：可作為餃子餡料，增添口感。</li>
                    </ul>
                </section>

                <section className={styles.articleContent}>
                    <h2 className={styles.sectionTitle}>五行的影響</h2>
                    <ul className={styles.list}>
                        <li>五行屬性：黑木耳屬於「木」，與生長、發展和調和有關。能促進人體的生理活動，增強免疫力。</li>
                        <li>滋陰潤肺：黑木耳在中醫中被認為具有滋陰潤肺的作用，適合體內燥熱或陰虧的人群，有助於調整肺部功能。</li>
                        <li>活血化瘀：黑木耳能促進血液循環，對於有淤血或氣滯的情況，有良好的改善效果，進而影響心臟和腎臟的健康。</li>
                    </ul>
                </section>

                <Footer />
            </div>
        </div>
    );
}

export default BlackFungusBenefits;

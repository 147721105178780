// Home.js
import React, { Component } from 'react';
import TagCloud from './TagCloud';
import ArticleLoader from './ArticleLoader';
import styles from './Style/Home.module.css';

export class Home extends Component {
    static displayName = Home.name;

    state = {
        tags: [
            { name: '28星宿', count: 0, url: '/ming/LunarMansions28Result', api: '/LunarMansions28Bazi' },
            { name: '八字算命', count: 0, url: '/ming/MingResult', api: '/CalculateBazi' },
            { name: '鬼谷子秤重', count: 0, url: '/ming/GuiGuZiResult', api: '/GuiGuZiBazi' },
            { name: '農曆出生日看出今生', count: 0, url: '/ming/LunarDayResult', api: '/LunarDayBazi' },
            { name: '三世書', count: 0, url: '/ming/ThreeLifetimesResult', api: '/ThreeLifetimesBazi' },
            { name: '時辰看個性', count: 0, url: '/ming/TimeCharacterResult', api: '/TimeCharacterBazi' },
            { name: '易經卜卦', count: 0, url: '/ming/IChingResult', api: '/IChingResultBazi' },
            { name: '瑪莉歐', count: 0, url: '/GameMario', api: '/GameMario' },
            { name: '憤怒鳥', count: 0, url: '/GameAngryBirds', api: '/GameAngryBirds' },
            { name: '水庫', count: 0, url: '/ReservoirGrid', api: '/Reservoir' },
            { name: '二十四節氣', count: 0, url: '/SolarTermSearch', api: '/SolarTermSearch' },
        ],
        currentTime: new Date().toLocaleString(),
    };

    componentDidMount() {
        this.fetchApiUsage();
        this.updateTime();
    }

    componentWillUnmount() {
        if (this.timeInterval) {
            clearInterval(this.timeInterval);
        }
    }

    async fetchApiUsage() {
        // Implement fetchApiUsage logic if needed
    }

    updateTime() {
        this.timeInterval = setInterval(() => {
            this.setState({
                currentTime: new Date().toLocaleString(),
            });
        }, 1000);
    }

    render() {
        return (
            <div className={styles.homeContainer}>
                <div className={styles.articleSection}>
                    <ArticleLoader />
                </div>
                <div className={styles.rightSection}>
                    <div className={styles.timeSection}>
                        <h2>{this.state.currentTime}</h2>
                    </div>
                    <div className={styles.tagSection}>
                        <TagCloud tags={this.state.tags} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
